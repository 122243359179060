<template>
  <Container>
    <div class="max-w-3xl mx-auto text-center">
      <h2 class="mb-4">{{ title }}</h2>
      <p class="text-lg text-gray-900 font-light">
        {{ description }}
      </p>
    </div>
    <dl
      class="
        mt-12
        space-y-10
        sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12
        lg:grid-cols-4 lg:gap-x-8
      "
    >
      <div v-for="feature in features" :key="feature.name" class="relative">
        <dt>
          <img :src="feature.icon" class="mb-4 h-7 w-7" alt="" aria-hidden="true" /></dt>
        <dt>
          <!-- <CheckIcon
            class="absolute h-6 w-6 text-green-500"
            aria-hidden="true"
          /> -->
          <p class="text-lg leading-6 font-medium text-gray-900">
            {{ feature.name }}
          </p>
        </dt>
        <dd class="mt-4 text-base text-gray-900 ">
          {{ feature.description }}
        </dd>
      </div>
    </dl>
  </Container>
</template>

<script>
import Container from "@/components/layouts/Container.vue";
import { CheckIcon } from "@heroicons/vue/outline";
const defaultFeatures = [
  {
    name: "Invite team members",
    description:
      "Tempor tellus in aliquet eu et sit nulla tellus. Suspendisse est, molestie blandit quis ac. Lacus.",
  },
  {
    name: "Notifications",
    description:
      "Ornare donec rhoncus vitae nisl velit, neque, mauris dictum duis. Nibh urna non parturient.",
  },
  {
    name: "List view",
    description:
      "Etiam cras augue ornare pretium sit malesuada morbi orci, venenatis. Dictum lacus.",
  },
  {
    name: "Boards",
    description:
      "Interdum quam pulvinar turpis tortor, egestas quis diam amet, natoque. Mauris sagittis.",
  },
  {
    name: "Keyboard shortcuts",
    description:
      "Ullamcorper in ipsum ac feugiat. Senectus at aliquam vulputate mollis nec. In at risus odio.",
  },
  {
    name: "Reporting",
    description:
      "Magna a vel sagittis aliquam eu amet. Et lorem auctor quam nunc odio. Sed bibendum.",
  },
  {
    name: "Calendars",
    description:
      "Sed mi, dapibus turpis orci posuere integer. A porta viverra posuere adipiscing turpis.",
  },
  {
    name: "Mobile app",
    description:
      "Quisque sapien nunc nisl eros. Facilisis sagittis maecenas id dignissim tristique proin sed.",
  },
];
export default {
  components: {
    CheckIcon,
    Container,
  },
  props: {
    features: {
      type: Array,
      default: () => defaultFeatures,
    },
    title: {
      type: String,
      default: "Features",
    },
    description: {
      type: String,
      default:
        "Ac euismod vel sit maecenas id pellentesque eu sed consectetur. Malesuada adipiscing sagittis vel nulla nec.",
    },
  },
};
</script>

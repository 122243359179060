<template>
  <main class="relative min-h-screen">
    <div class="absolute inset-0 w-full h-full">
      <img src="/cars-img/escalade-ev/index.jpg" alt="" class="w-full h-full object-cover flipped" />
    </div>
    <div class="absolute inset-0 w-full h-full bg-gradient-to-r from-accent-darkest mix-blend-multiply "/>
    <div class="absolute inset-0 w-full h-full bg-accent-darkest opacity-50"/>
    <div class="grid items-center  min-h-screen">
      <Container>
        <slot></slot>
      </Container>
    </div>
  </main>
</template>

<script>
import Container from "@/components/layouts/Container";
export default {
  components: {
    Container,
  },
};
</script>

<style>
.flipped {
  transform: scaleX(-1);
}
</style>
